import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "animate.css/animate.min.css";

// Set up theme for MUI
let theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Roboto Mono',
      'monospace'
    ].join(","),
    fontSize: 12,
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Clear local storage on new instance
window.onload = function() {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
}